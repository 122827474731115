.games {
    display: flex;
    gap: 2rem;
}

.container_games {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.games__list {
    gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
}

@media screen and (max-width: 1000px) {
    .games {
        flex-direction: column;
    }

    .category {
        width: 100%;
    }

    .games__list {
        flex: auto;
    }
}