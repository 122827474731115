.logo {
    color: #13ADF5;
    cursor: pointer;
}

.logo svg {
    font-size: 8rem;
}

@media screen and (max-width: 500px) {
    .logo svg {
        font-size: 5rem;
    }
}