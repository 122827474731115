.box {
    background-color: #0C0F27;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 48px 100px 0px;
    color: #FFFCFF;
    border-radius: 2rem;
    padding: 1rem;
    width: 95%;
    min-height: 95vh;
    margin: 0 auto;
    padding-bottom: 6rem;
    position: relative;
}