.footer {
    width: 100%;
    border-radius: 2rem;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid #695ED4;
    bottom: 0;
    left: 0;
    position: absolute;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;
}

.footer h3 {
    font-size: 1.8rem;
}

@media screen and (max-width: 700px) {
    .footer h3 {
        font-size: 1.2rem;
    }
}