.page_favorites {
    width: 80%;
    margin: 0 auto;
}

.page_favorites h3 {
    text-align: center;
    margin-bottom: 3rem;
}

.page_favorites p {
    text-align: center;
    color: #FBB034;
    font-weight: 500;
}