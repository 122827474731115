.pages {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin: 0 auto;
}

.btn_page {
    width: 4rem;
    align-self: flex-start;
    height: 4rem;
    border: none;
    border-radius: .5rem;
    color: white;
    cursor: pointer;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 4px;
};

.btn_page:hover {
    transform: translateY(6px);
    color: black;
}