.box {
    margin-top: 0;
}

.game_box {
    position: relative;
}

.game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: inherit;
    border: 1px solid #695ED4;
    border-radius: 2rem;
    padding: 1rem 0;
    cursor: pointer;
    text-decoration: none;
}

.game_info {
    font-size: 1.3rem;
    font-weight: 500;
}

.game img {
    width: 100%;
}

.game_title {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 600;
    color: #FBB034;
}

.game_icon_fav {
    position: absolute;
    right: 0;
    border: none;
    background-color: transparent;
    margin: .5rem .7rem 0 0;
    cursor: pointer;
    transition: all .5s;
}

.game_icon_fav svg {
    font-size: 2.5rem;
    color: #FBB034;
}

.game_icon_fav svg:active {
    transform: scale(1.1);
}