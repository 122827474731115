.category {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    align-items: flex-start;
    width: 18%;
}

.tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.category button {
    border: none;
    font-size: 1.5rem;
    padding: 2px;
    background-color: transparent;
    color: #13ADF5;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
}


@media screen and (max-width: 500px) {
    .category h3 {
        font-size: 1.5rem;
    }
    .category svg {
        font-size: 1rem;
    }
    .category button {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1000px) {
    .category {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
};