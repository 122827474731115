.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4rem;
}

@media screen and (max-width: 500px) {
    .header {
        flex-direction: column;
        margin: 1rem;
    }
}