.nav ul {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.nav ul li {
    list-style: none;
}

.nav ul a{
    font-size: 2rem;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    transition: all .5s;
}

.nav ul a:hover {
    color: #13ADF5;
}

.fav_total {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .3rem;
}

.fav_total svg{
    color: #FBB034;
}

.fav_total p{
    font-size: 2.5rem;
    color: #FBB034;
    font-weight: 600;
}
