.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: #67c1f5;
    font-size: 2.5rem;
}

.spin {
    width: 8rem;
    height: 8rem;
    border: 1.2rem solid #67c1f5;
    border-radius: 50%;
    border-top: 1.2rem solid #695ED4;
    animation: spinner 2s linear infinite;
}

@keyframes spinner {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}