.box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    gap: 2rem;
    width: 75%;
    border: 1px solid #695ED4;
    padding: 1rem;
    border-radius: 1rem;
    margin: auto;
    box-shadow: rgba(105, 94, 212, 0.35) 0px 5px 15px;
}

.game {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    position: relative;
}

.game_left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 36rem;
}

.game_left img {
    width: 100%;
}

.game_left p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #695ED4;
}

.game_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.game_right h2 {
    text-align: center;
}

.game_right_details {
    font-size: 1.5rem;
    font-weight: 400;
    color: #695ED4;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.game_right_details span, .game_left span, .game_sys span {
    font-weight: 500;
    color: #67c1f5;
}

.btn_play a {
    text-decoration: none;
}

.btn_play button {
    font-size: 1.5rem;
    font-weight: 500;
    color: #FFFCFF;
    background-image: linear-gradient(45deg,#695ED4, #1B0C43);
    padding: 1rem 1.5rem;
    border: none;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    transition: all .5s;
}

.btn_play button svg {
    font-size: 2rem;
    color: #67c1f5;
}

.btn_play button:active {
    transform: scale(1.05);
}

.screenshots {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    width: 100%;
}

.screenshot img {
    width: 100%;
    cursor: pointer;
}

.screenshot_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s;
    animation: openModal 1s;
    transform-origin: center center;
    z-index: 2;
}

.screenshot_modal img {
    width: 70vw;
}

.screenshot_modal svg {
    font-size: 3rem;
    color: #67c1f5;
    padding: 2px;
    background-color: #FFFCFF;
    border-radius: .5rem;
    transition: all .3s;
}

.screenshot_modal svg:hover {
    color: #695ED4;
}

.close {
    position: absolute;
    right: 0;
    cursor: pointer;
}

.game__icon_fav {
    position: absolute;
    right: 0;
    border: none;
    background-color: transparent;
    margin: .5rem .7rem 0 0;
    cursor: pointer;
    transition: all .5s;
    z-index: 1;
}

.game__icon_fav svg {
    font-size: 2.5rem;
    color: #FBB034;
}

.game__icon_fav svg:active {
    transform: scale(1.1);
}

@keyframes openModal {
    0% {
        scale: 0;
    }

    100% {
        scale: 1;
    }
}

.game_sys {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.game_sys h3 {
    font-size: 1.5rem;
    color: #FE8100;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.game_sys p {
    font-size: 2rem;
    font-weight: 400;
    color: #695ED4;
}

.game_sys p:not(:last-child) {
    margin-bottom: 1rem;
}

.game_sys svg {
    background-color: #FE8100;

}

@media screen and (max-width: 700px) {
    .box {
        width: 95%;
    }
}


@media screen and (max-width: 500px) {
    .game_right h2 {
        font-size: 2rem;
    }

    .game_sys h3 {
        font-size: 1.3rem;
    }

    .game_sys p {
        font-size: 1.2rem;
    }
}